import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import LoanPrograms from "../components/Repeating/LoanProgramsGridRow";
// import HomeWorth from "../components/Repeating/HomeWorth";
import ValueProps from "../components/Repeating/ValueProps";
import FAQs from "../components/Repeating/FAQs";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

import benefit1 from "../images/3.0 Services/Private Loans/Qualify with a lower credit score.svg";
import benefit2 from "../images/3.0 Services/Private Loans/Flexiblity.svg";
import benefit3 from "../images/3.0 Services/Private Loans/Shorter approval process.svg";
import benefit4 from "../images/3.0 Services/Private Loans/Less red tape.svg";

const Page = ({ data }) => {
  const faqs = [
    {
      question: "Why should I get a private loan?",
      answer: (
        <>
          <p>
            A private loan has some big advantages. It allows you to work
            directly with a smaller company or an individual that can sometimes
            offer you more favorable loan terms. Also, you can usually qualify
            while having a lower credit score.
          </p>
        </>
      ),
    },
    {
      question: "What credit score do I need?",
      answer: (
        <>
          <p>
            Some private lenders have no minimum nor any credit score
            requirement; however, if your credit score is no less than 500, you
            may have more choices.
          </p>
        </>
      ),
    },
    {
      question: "Who can I trust when getting a private loan?",
      answer: (
        <>
          <p>
            Getting a private loan from an individual is inherently riskier.
            It’s generally best to use a qualified, trusted mortgage company
            with a history of providing various loan types. Even better, you
            should use an attorney-owned and operated mortgage company to be
            sure you have the highest level of service and protection possible.
          </p>
        </>
      ),
    },
    {
      question: "Is there anything else I should be aware of?",
      answer: (
        <>
          <p>
            It’s good to know going in that typically, you’ll need a larger down
            payment for a private loan. Your monthly payments may also be higher.
          </p>
        </>
      ),
    },
  ];

  return (
    <Layout>
      <SearchEngineOptimization
        title="Hard Money Loans Orange County | Nikkael"
        description="If you have a low credit score or high debt-to-income ratio, we can help you with a hard money loan to help you get the mortgage you want. Contact us today!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Hard Money Loans in Orange County</h1>
              <p>
                Traditional mortgages can come with some downfalls such as
                stricter qualification requirements. A private loan, or hard
                money loan, allows you to get the mortgage you want even if you
                have a lower credit score or high debt-to-income ratio. That’s
                because you work with a private lender that has its own
                requirements.
              </p>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/3.0 Services/Private Loans/1.0 Private Loans.jpg"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <header className="mb-12 md:mb-16">
            <h2>Benefits of Private Loans</h2>
          </header>

          <div className="grid gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit1} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">
                  Qualify with a lower credit score
                </h3>
                <p className="mb-0">
                  Get good terms on your mortgage without needing a 670+ credit
                  score
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit2} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">More flexibility</h3>
                <p className="mb-0">
                  Work privately with a company or individual and negotiate your
                  terms and requirements
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit3} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Shorter approval process</h3>
                <p className="mb-0">
                  Get a loan without extensive appraisals, inspections, and
                  required documents
                </p>
              </div>
            </div>
            <div className="items-start md:flex md:space-x-8">
              <img src={benefit4} className="mb-3 flex-1 md:mb-0" />
              <div>
                <h3 className="heading-four mb-2">Less red tape</h3>
                <p className="mb-0">
                  Move faster through the loan process and get the keys to your
                  home quicker
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/3.0 Services/eligibility.jpg"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>When Does Private Lending Make Sense?</h2>
              <p>
                You can benefit from a hard money home loan in California when:
              </p>
              <ul className="styled-list-checkmark mb-6">
                <li>
                  Buying a type of property that traditional lenders won’t
                  finance
                </li>
                <li>You’re unable to qualify under traditional financing</li>
                <li>You want to jump through fewer hoops</li>
                <li>You have blemished credit</li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Request Rates" />
            </div>
          </div>
        </div>
      </section>

      {/* <HomeWorth /> */}
      <FAQs heading="Common Questions About Private Loans" uniqueFaqs={faqs} />
      <ValueProps />
      <Testimonials />
      <LoanPrograms
        heading="You Might Also Be Interested In"
        hidePrograms={[3, 5]}
      />
      <CallToAction
        heading="Considering a Private Loan?"
        text="We’re a top private home loan broker in Orange County, and we’re armed with answers to your questions. Contact us today!"
      />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-private-loans.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-private-loans.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
